:root {
  --chitin-primary: var(--brightscrip-black);
  --chitin-primary-h: var(--brightscrip-black-h);
  --chitin-primary-s: var(--brightscrip-black-s);
  --chitin-primary-l: var(--brightscrip-black-l);
  --chitin-primary-shaded: var(--brightscrip-black-70);
  --chitin-primary-complementary: var(--brightscrip-black-complementary);

  --chitin-secondary: var(--brightscrip-pink);
  --chitin-secondary-shaded: var(--brightscrip-pink-90);
  --chitin-secondary-complementary: var(--brightscrip-pink-complementary);

  --chitin-page-text: var(--brightscrip-black);

  --chitin-page-bg-h: 216;
  --chitin-page-bg-s: 45.5%;
  --chitin-page-bg-l: 97.8%;
  --chitin-page-bg: hsl(var(--chitin-page-bg-h), var(--chitin-page-bg-s), var(--chitin-page-bg-l)); // TODO

  // Form input variables
  --chitin-form-input-bg: transparent;
  --chitin-form-input-color: var(--chitin-page-text);
  --chitin-form-input-border-color: var(--bs5-border-color);
  --chitin-form-input-focus-border-color: hsla(var(--chitin-primary-h), var(--chitin-primary-s), var(--chitin-primary-l), 0.5);
  --chitin-form-input-focus-box-shadow-color: hsla(var(--chitin-primary-h), var(--chitin-primary-s), var(--chitin-primary-l), 0.25);
  --chitin-form-input-label-color: var(--chitin-page-text);
  --chitin-form-input-clear-button-color: var(--chitin-page-text);
  --chitin-form-input-clear-button-hover-color: #d0021b;
  --chitin-form-input-invalid-border-color: var(--brightscrip-danger);

  // Datepicker variables
  --chitin-datepicker-bg: var(--chitin-page-bg, white);
  --chitin-datepicker-color: hsl(var(--brightscrip-black-h), var(--brightscrip-black-s), 35%);
  --chitin-datepicker-border-color: hsl(var(--brightscrip-black-h), var(--brightscrip-black-s), 90%);
  --chitin-datepicker-cell-color: var(--chitin-page-text);
  --chitin-datepicker-cell-hover-bg: hsla(var(--chitin-primary-h), var(--chitin-primary-s), var(--chitin-primary-l), 0.2);
  --chitin-datepicker-selected-cell-color: var(--chitin-primary-complementary);
  --chitin-datepicker-selected-cell-bg: var(--chitin-primary);
  --chitin-datepicker-today-cell-border-color: var(--chitin-primary);
  --chitin-datepicker-cell-in-range-bg: hsla(var(--chitin-primary-h), var(--chitin-primary-s), var(--chitin-primary-l), 0.15);

  // Select variables
  --chitin-select-input-dropdown-panel-bg: var(--chitin-page-bg, white);
  --chitin-select-input-dropdown-panel-color: var(--chitin-page-text);
  --chitin-select-input-dropdown-panel-border-color: hsl(var(--brightscrip-black-h), var(--brightscrip-black-s), 35%);
  --chitin-select-input-dropdown-panel-item-bg: var(--chitin-page-bg, white);
  --chitin-select-input-dropdown-panel-item-color: var(--chitin-page-text);
  --chitin-select-input-dropdown-panel-item-hover-bg: hsla(var(--chitin-primary-h), var(--chitin-primary-s), var(--chitin-primary-l), 0.15);
  --chitin-select-input-dropdown-panel-item-hover-color: var(--chitin-page-text);
  --chitin-select-input-dropdown-panel-selected-item-bg: hsla(
    var(--chitin-primary-h),
    var(--chitin-primary-s),
    var(--chitin-primary-l),
    0.15
  );
  --chitin-select-input-dropdown-panel-selected-item-color: var(--chitin-page-text);
  --chitin-select-input-selected-item-bg: hsla(var(--chitin-primary-h), var(--chitin-primary-s), var(--chitin-primary-l), 0.15);
  --chitin-select-input-selected-item-color: var(--chitin-page-text);
  --chitin-select-input-invalid-border-color: var(--brightscrip-danger);

  // Spinner variables
  --chitin-spinner-color: var(--chitin-page-text);

  // Icon variables
  --chitin-icon-color: var(--chitin-page-text);

  // Pharmacy dot
  --chitin-pharmacy-dot-busy-bg-color: rgb(255, 34, 62);
  --chitin-pharmacy-dot-busy-box-shadow-color: rgba(255, 34, 62, 0.7);
  --chitin-pharmacy-dot-idle-bg-color: rgb(153, 153, 153);
  --chitin-pharmacy-dot-idle-box-shadow-color: rgba(153, 153, 153, 0.7);
}
