$enable-cssgrid: true;
$default-box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.1);

$white: #fff;
$black: #000;

$body-color: $gray-600;
$body-color-dark: $white;
$body-bg-dark: #202634;

$blue: #1e3050;
$font-family-sans-serif:
  'Poppins',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  'Helvetica Neue',
  Arial,
  sans-serif;

$font-size-base: 14px;
$font-size-base-sm: $font-size-base * 0.85;

$h1-font-size: $font-size-base * 1.75;
$h2-font-size: $font-size-base * 1.45;
$h3-font-size: $font-size-base * 1.35;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.15;
$h6-font-size: $font-size-base;

$blue: #1e3050;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #fd7083;
$red: #d9534f;
$orange: #ffa20f;
$yellow: #e5a54b;
$green: #4bbf73;
$teal: #20c997;
$cyan: #1f9bcf;
$dark: #495057;

$light: #f0f0f0;

$body-bg: $white;
$body-bg-dark: #202634;

$primary: $blue;
$warning: $orange;
$gray: #ebebeb;

$success: $green;

$link-color: $primary;

$theme-colors: (
  'primary': $primary,
  'secondary': $gray,
  'success': $success,
  'info': $cyan,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'pink': $pink,
);

$input-bg: var(--#{$prefix}body-bg);
$component-active-bg: $primary;
$component-active-color: $white;
$focus-ring-width: 0.25rem;
$focus-ring-opacity: 0.25;
$focus-ring-color: rgba($primary, $focus-ring-opacity);
$focus-ring-blur: 0;
$focus-ring-box-shadow: 0 0 $focus-ring-blur $focus-ring-width $focus-ring-color;
$input-focus-bg: $input-bg;
$input-focus-border-color: tint-color($component-active-bg, 50%);

/* Headlines */
$headings-margin-bottom: 0;
$headings-color: $black;

/* Spacer */
$spacer: 0.75rem;
$grid-gutter-width: 1rem;

/* Border radius */
$border-radius: 0.75rem;
$border-radius-sm: 0.25rem;

$headings-font-weight: 400;

/* Buttons */
$btn-font-size: $font-size-base;

$input-btn-padding-y: 0.375rem;
$input-btn-padding-x: 0.75rem;
$input-btn-font-size: 1rem;
$input-btn-line-height: 1;
$btn-border-radius: 0.5rem;
$btn-box-shadow: $default-box-shadow;

$btn-border-radius-sm: $border-radius-sm;
$input-btn-padding-y-sm: 0.375rem;
$input-btn-padding-x-sm: 0.75rem;
$input-btn-font-size-sm: 0.85rem;

$input-btn-padding-y-lg: 0.75rem;
$input-btn-padding-x-lg: 0.75rem;

/* Cards */
$card-border-width: 0;
$card-border-radius: var(--#{$prefix}border-radius);
$card-box-shadow: $default-box-shadow;
$card-cap-bg: var(--#{$prefix}white);
$card-bg: var(--#{$prefix}body-bg);
$card-title-color: $headings-color;
$card-color: $gray-800;
$card-spacer-x: 0.75rem;
$card-spacer-y: 0.75rem;

.card {
  box-shadow: var(--bs5-card-box-shadow);
}

/* Dropdowns */
$dropdown-link-active-bg: $primary;
$dropdown-font-size: $font-size-base;
$dropdown-link-color: $body-bg-dark;

$dropdown-link-hover-color: $white;
$dropdown-link-hover-bg: $primary;

/* Switch / Checkbox */
$form-check-input-bg: $input-bg;
$form-check-input-focus-border: $input-focus-border-color;
$form-check-input-focus-box-shadow: $focus-ring-box-shadow;
$form-check-input-checked-color: $component-active-color;
$form-check-input-checked-bg-color: $component-active-bg;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");

$form-check-input-indeterminate-color: $component-active-color;
$form-check-input-indeterminate-bg-color: $component-active-bg;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");

$form-switch-color: rgba($black, 0.25);
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>");
$form-switch-focus-color: $input-focus-border-color;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");
$form-switch-checked-color: $component-active-color;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>");
$form-switch-checked-bg-position: right center;

/* Inputs */
$input-border-radius: var(--#{$prefix}-input-border-radius);

:root {
  --bs5--input-border-radius: 0.5rem;
}

/* Input Form Labels */
$form-label-font-size: $font-size-base;
$form-label-font-style: null;
$form-label-font-weight: null;
$form-label-color: $black;
$form-label-margin-bottom: 0;

$form-label-font-size-lg: $font-size-base;

//$form-text-margin-top:                  .25rem;
//$form-text-font-size:                   $small-font-size;
//$form-text-font-style:                  null;
//$form-text-font-weight:                 null;
$form-text-color: $form-label-color;

/* Link */
$link-hover-color: $black;
$link-hover-decoration: underline;

/* Navbar */
$navbar-padding-y: $spacer * 0.875;
$navbar-padding-x: $spacer * 1.25;

/* Pagination */
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $component-active-bg;

/* Table */
$table-color: $body-color;

/* List Group */
$list-group-border-width: 0;
$list-group-item-padding-y: $spacer;
$list-group-item-padding-x: 1.25rem;
$list-group-active-border-color: $component-active-bg;
$list-group-active-bg: $component-active-bg;
$list-group-disabled-color: $primary;

/* Tooltips */
$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 200px;
$tooltip-color: var(--#{$prefix}emphasis-color);
$tooltip-bg: var(--#{$prefix}body-bg);
$tooltip-border-radius: var(--#{$prefix}border-radius);
$tooltip-opacity: 1;
$tooltip-padding-y: $spacer * 0.25;
$tooltip-padding-x: $spacer * 0.5;
$tooltip-margin: null; // TODO: remove this in v6

$tooltip-arrow-width: 0.8rem;
$tooltip-arrow-height: 0.4rem;
// fusv-disable
$tooltip-arrow-color: null; // Deprecated in Bootstrap 5.2.0 for CSS variables
// fusv-enable
