chitin-select-old {
  .ng-select {
    input {
      color: var(--chitin-form-input-color);
    }

    &.ng-select-opened > .ng-select-container,
    .ng-select-container {
      background-color: var(--chitin-form-input-bg);
      color: var(--chitin-form-input-color);
      border-color: var(--chitin-form-input-border-color);
    }

    &.ng-select-opened.ng-select-bottom > .ng-select-container {
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    &.ng-select-opened.ng-select-top > .ng-select-container {
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }

    &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
      border-color: var(--chitin-form-input-focus-border-color);
      box-shadow: 0 0 0 0.25rem var(--chitin-form-input-focus-box-shadow-color);
    }

    &:disabled,
    &.disabled {
      opacity: 0.5;
    }

    &.form-control {
      padding: 0;
      border: unset;
      height: 34px;
      font-size: 0.825rem;

      &.ng-select-multiple {
        .ng-value {
          margin-bottom: 1px !important;
          margin-right: 3px !important;

          .ng-value-label {
            font-size: 0.825rem;
          }
        }
      }

      .ng-select-container {
        border-radius: $input-border-radius;
        min-height: unset;
        height: 34px;

        .ng-value-container {
          padding-top: 0;
          padding-left: 0.75rem;
          flex-wrap: nowrap;
          min-width: 0;

          .ng-value {
            font-size: 0.825rem;

            .ng-value-label {
              font-size: 0.825rem;
            }
          }

          .ng-placeholder {
            padding: 0 !important;
            top: unset !important;
            position: relative !important;
            text-overflow: ellipsis;
            overflow: hidden !important;
            white-space: nowrap;
            max-width: 100%;
          }
        }

        .ng-input {
          position: absolute;
          top: 0.35rem;
          left: 0.65rem;
        }
        &.ng-has-value {
          .ng-input {
            // position: static;
            pointer-events: none;
            flex-basis: 0;
          }
        }
      }

      &.form-control-sm {
        min-height: 30px;
        height: 30px;
        font-size: 0.75rem;

        .ng-select-container {
          min-height: 30px;
          height: 30px;
          border-radius: var(--bs5-border-radius-sm);

          .ng-value-container {
            padding: 0 0.5rem;

            .ng-value {
              font-size: 0.75rem;

              .ng-value-label {
                font-size: 0.75rem;
              }
            }
          }
        }

        &.ng-select-multiple {
          .ng-value {
            margin-bottom: 1px !important;
            margin-right: 2px !important;

            .ng-value-label {
              font-size: 0.825rem;
            }
          }
        }
      }

      &.form-control-lg {
        min-height: calc(2.24375rem + 2px);
        font-size: 0.95rem;

        .ng-select-container {
          min-height: calc(2.24375rem + 2px);
          border-radius: var(--bs5-border-radius-lg);

          .ng-value-container {
            padding: 0 1rem;

            .ng-value {
              font-size: 0.95rem;

              .ng-value-label {
                font-size: 0.95rem;
              }
            }
          }
        }

        &.ng-select-multiple {
          .ng-value {
            margin-bottom: 2px !important;
            margin-right: 4px !important;

            .ng-value-label {
              font-size: 0.825rem;
            }
          }
        }
      }
    }

    &.ng-select-multiple {
      & > .ng-select-container.ng-has-value {
        .ng-placeholder {
          display: none !important;
        }

        .ng-value {
          display: block !important;
          background-color: var(--chitin-select-input-selected-item-bg);
          color: var(--chitin-select-input-selected-item-color);
          border-radius: 0.2rem;

          .ng-value-icon {
            &.left {
              border-right: unset;
            }

            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }

    &.button-select {
      min-height: 2.149rem;

      .ng-select-container {
        min-height: 2.149rem;
      }
    }
  }
}

.ng-dropdown-panel {
  z-index: 1200 !important;
  padding: 0;
  background-color: var(--chitin-select-input-dropdown-panel-bg);
  border-color: $table-border-color;
  color: var(--chitin-select-input-dropdown-panel-color);
  z-index: 2000;

  &.width-auto {
    width: auto !important;
  }

  .ng-dropdown-panel-items {
    .ng-option {
      background-color: var(--bs5-white);
      // color: var(--bs5-body-color);

      &.ng-option-selected {
        background-color: $primary !important;
        color: $white !important;
      }

      &.ng-option-marked:not(.ng-option-selected) {
        background-color: hsl(221, 39.3%, 95%) !important;
        // color: var(--bs5-body-color) !important;
      }

      .ng-option-label {
        font-size: 0.825rem;
      }
    }
  }

  &.ng-select-bottom {
    border-top: unset;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  &.ng-select-top {
    border-bottom: unset;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

chitin-select.is-invalid .ng-select .ng-select-container {
  border-color: var(--chitin-select-input-invalid-border-color);
}
