html.dark {
  --chitin-primary: var(--brightscrip-pink);
  --chitin-primary-h: var(--brightscrip-pink-h);
  --chitin-primary-s: var(--brightscrip-pink-s);
  --chitin-primary-l: var(--brightscrip-pink-l);
  --chitin-primary-shaded: var(--brightscrip-pink-90);
  --chitin-primary-complementary: var(--brightscrip-pink-complementary);

  --chitin-secondary: var(--brightscrip-black);
  --chitin-secondary-shaded: var(--brightscrip-black-70);
  --chitin-secondary-complementary: var(--brightscrip-black-complementary);

  --chitin-page-text: white;
  --chitin-page-bg-h: var(--brightscrip-black-h);
  --chitin-page-bg-s: var(--brightscrip-black-s);
  --chitin-page-bg-l: var(--brightscrip-black-l);
  --chitin-page-bg: hsl(var(--chitin-page-bg-h), var(--chitin-page-bg-s), var(--chitin-page-bg-l));
}
