$prefix: 'bs5-';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/variables-dark';

@import 'variables';
@import 'variables-dark';

@import 'appstack/1-variables/app';

// Bootstrap
@import 'bootstrap/scss/bootstrap';

@import 'bootstrap/scss/bootstrap-reboot';
@import 'bootstrap/scss/bootstrap-grid';
@import 'bootstrap/scss/bootstrap-utilities';
@import 'bootstrap/scss/modal';

@import 'keen-slider/keen-slider.min.css';

$stat-icon-color: $blue;
$stat-bg: lighten($blue, 35%);

@import 'appstack/app';

@import 'chitin/chitin';

@import 'bootstrap-overrides/card';
@import 'bootstrap-overrides/tooltip';
@import 'bootstrap-overrides/alert';

@import 'login';

html {
  font-size: 16px;
}

main.content {
  background: radial-gradient(#ffffff, #f0f0f0);
  height: 100%;
}

// Color mode variables in _root.scss
@include color-mode(dark) {
  main.content {
    background: radial-gradient(#2c354a, #202634);
  }
}

.ps {
  position: relative;
}

.empty-table {
  text-align: center;
  vertical-align: middle;
  background: transparent;
  box-shadow: none !important;
  border: none;

  p {
    margin: 20px 0 0;
    font-size: 30px;
    font-weight: normal;
    color: #b9b9b9;
  }

  .alert {
    padding: 0;
    margin: 16px;
    justify-content: center;
  }
}

html {
  font-size: 100%;
}

body {
  overflow-y: auto;
}

.text-link {
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

h5 {
  font-weight: 500;
}

@include color-mode(dark) {
  .desktop-scrollable {
    thead {
      th {
        background: linear-gradient(to top, #202634, #202634 1px, #202634 1px, #202634 100%) !important;
      }
    }
  }
}

.table {
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: $table-border-color;
  }
}

a {
  cursor: pointer;
}

.modal-bg-gray {
  .modal-content {
    background-color: #f0f0f0;
  }
}

.list-group {
  box-shadow: $default-box-shadow;
}

.no-decoration {
  text-decoration: none !important;
}

.accordion {
  --bs5-accordion-active-bg: white;
  --bs5-accordion-active-color: black;
}

.btn-xs {
  --bs5-btn-padding-y: 0;
  --bs5-btn-padding-x: 0.25rem;
  --bs5-btn-font-size: 0.775rem;
  --bs5-btn-border-radius: 0.25rem;
}

.btn-close {
  --bs5-btn-close-opacity: 0.8;
}

.badge {
  --bs5-badge-font-weight: 500;
  --bs5-badge-border-radius: 0.25em;
  --bs5-badge-font-size: 0.8em;
}

perfect-scrollbar,
.perfect-scrollbar,
[ng-reflect-perfect-scrollbar] {
  overflow: auto;
}

formly-field .form-label {
  display: block;
  min-height: 20px;
}

// Angular Material bug workaround
// Read more here: https://github.com/h2qutc/angular-material-components/issues/348
.mat-datepicker-content {
  --mat-datepicker-calendar-container-background-color: #fff;
}

body {
  --Color-Violet: #6f42c1;
  --Color-Lighter-Blue: #334e7e;
  --Color-Light-Grey: #f6f6f6;
  --Color-Dark-Grey: #8a8a8a;
}
.btn-outline-lighter-blue {
  border: 1px solid var(--Color-Lighter-Blue, #334e7e);
  color: var(--Color-Lighter-Blue, #334e7e);
  &:hover,
  &:active {
    background: var(--Color-Lighter-Blue, #334e7e);
    color: #fff;
  }
}
.bg-purple {
  background: var(--Color-Violet, #6f42c1);
}
