@import 'bootstrap/scss/variables';

.table {
  font-size: 0.9rem;
}

.empty-table {
  text-align: center;
  vertical-align: middle;
  background: transparent;
  box-shadow: none !important;
  border: none;

  p {
    margin: 20px 0 0;
    font-size: 30px;
    font-weight: normal;
    color: #b9b9b9;
  }

  .alert {
    padding: 0;
    margin: 16px;
  }
}

.desktop-scrollable {
  overflow: auto;

  thead {
    position: sticky;
    top: 0;
    z-index: 9;

    th {
      // the following code fixes this bug: https://codepen.io/Ray-H/pen/bMedLL
      background: linear-gradient(to top, rgb(222, 230, 237), rgb(222, 230, 237) 2px, $body-bg 2px, $body-bg 100%);
      border: none;

      font-size: 0.85rem;
      font-weight: 500;
      color: $black;

      &.multi-header {
        background: $body-bg;
        font-weight: 400;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          bottom: -26px;
          top: 10px;
          left: 0px;
          border-left: 1px solid rgb(222, 230, 237);
        }
      }
    }
  }
}

.bs-table {
  td {
    .cell-label {
      display: none;
    }
  }

  .tfilter {
    input::placeholder,
    .ng-placeholder {
      opacity: 0;
    }
  }

  .ng-select-multiple .ng-has-value {
    .ng-placeholder {
      opacity: 1;
    }
  }

  .checkAble {
    width: 1%;
  }
}

@media (max-width: map-get($grid-breakpoints, 'lg')) {
  .bs-table:not(.force-desktop-layout) {
    table,
    thead,
    tbody,
    tr {
      display: block;
    }

    tr {
      background: white;
      box-shadow: 0 0 0.875rem 0 rgb(41 48 66 / 5%);
      border: none;
      border-radius: 0.35rem;
      margin-bottom: 1rem;
      padding: 10px 10px 1px;
    }

    th {
      display: block;
      width: 100% !important;
      border: none;
    }

    td {
      display: flex;
      width: 100% !important;
      box-shadow: none !important;
      padding: 0 !important;
      margin-bottom: 10px;
      border: none;

      .cell-label {
        display: inline-block;
        font-weight: bold;
      }

      .cell-value {
        text-align: right;
        flex: 1 1 auto;
      }
    }

    .thead {
      display: none;
    }

    .tfilter {
      display: none;
      padding: 10px 10px 1px;

      th {
        padding: 0 !important;
        margin-bottom: 10px;
      }

      input::placeholder,
      .ng-placeholder {
        opacity: 1;
      }
    }

    &.show-filters {
      .tfilter {
        display: block;
      }
    }

    .checkAble {
      display: none;
    }
  }
}
.app-table__number-of-items-being-shown {
  font-size: var(--bs5-body-font-size) !important;
}
