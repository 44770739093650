@use 'sass:color';
@use 'sass:map';
@use 'sass:math';

$colors: (
  brightscrip-black: (
    h: 210,
    s: 11%,
    l: 15%,
    complementary: rgb(255, 255, 255),
  ),
  brightscrip-pink: (
    h: 350,
    s: 94.3%,
    l: 65.9%,
    complementary: rgb(255, 255, 255),
  ),
  brightscrip-blue: (
    h: 218,
    s: 45%,
    l: 22%,
    complementary: rgb(255, 255, 255),
  ),
  brightscrip-gray: (
    h: 0,
    s: 0%,
    l: 92%,
    complementary: rgb(0, 0, 0),
  ),
  brightscrip-success: (
    h: 141,
    s: 48%,
    l: 59%,
    complementary: rgb(255, 255, 255),
  ),
  brightscrip-danger: (
    h: 2,
    s: 64%,
    l: 58%,
    complementary: rgb(255, 255, 255),
  ),
  brightscrip-warning: (
    h: 35,
    s: 75%,
    l: 63%,
    complementary: rgb(255, 255, 255),
  ),
  brightscrip-info: (
    h: 198,
    s: 74%,
    l: 38%,
    complementary: rgb(255, 255, 255),
  ),
);
$colorsShades: 50, 60, 70, 80, 90, 100, 105, 110, 115, 120, 125;

@mixin generateColors($type, $color, $shade) {
  #{$type}: hsl(
    var(--#{$color}-h),
    calc(var(--#{$color}-s) + (100% - var(--#{$color}-s)) * #{(100 - $shade) * 0.025}),
    calc(var(--#{$color}-l) + (100% - var(--#{$color}-l)) * #{($shade - 100) * 0.035})
  );
}

:root {
  @each $color, $value in $colors {
    --#{$color}-h: #{map.get($value, h)};
    --#{$color}-s: #{map.get($value, s)};
    --#{$color}-l: #{map.get($value, l)};
    --#{$color}: hsl(var(--#{$color}-h), var(--#{$color}-s), var(--#{$color}-l));
    @each $shade in $colorsShades {
      @include generateColors(--#{$color}-#{$shade}, $color, $shade);
    }
    --#{$color}-complementary: #{map.get($value, complementary)};

    .text-#{$color} {
      color: var(--#{$color});
    }
    .bg-#{$color} {
      background-color: var(--#{$color});
      color: var(--#{$color}-complementary);
    }
    .text-forbg-#{$color} {
      color: var(--#{$color}-complementary);
    }

    @each $shade in $colorsShades {
      .text-#{$color}-#{$shade} {
        @include generateColors(color, $color, $shade);
      }
      .bg-#{$color}-#{$shade} {
        @include generateColors(background-color, $color, $shade);
      }
    }
  }
}

$incyte-colors: (
  dark: rgb(22, 26, 61),
  navy: rgb(26, 16, 85),
  coral: rgb(242, 91, 91),
  blue: rgb(105, 182, 229),
  light-blue: rgb(202, 220, 242),
  peach: rgb(248, 200, 190),
  gold: rgb(204, 152, 111),
  warm-gray: rgb(216, 214, 215),
  bright-blue-teal: rgb(43, 164, 178),
  bright-teal: rgb(0, 139, 154),
  bright-teal-dark: rgb(20, 97, 109),
);

$bs-incyte-colors: (
  primary: map.get($incyte-colors, navy),
  secondary: map.get($incyte-colors, peach),
  success: map.get($incyte-colors, bright-blue-teal),
  danger: map.get($incyte-colors, coral),
  warning: map.get($incyte-colors, gold),
  info: map.get($incyte-colors, blue),
  light: map.get($incyte-colors, light-blue),
);

:root {
  @each $key, $value in $incyte-colors {
    --incyte-#{"" + $key}: #{$value};
  }
  @each $key, $value in $bs-incyte-colors {
    --incyte-#{"" + $key}: #{$value};
  }
}

.incyte-page {
  @each $key, $value in $bs-incyte-colors {
    --bs5-#{"" + $key}: #{$value};
    --bs5-#{"" + $key}-rgb: #{color.red($value), color.green($value), color.blue($value)};
    --bs5-#{"" + $key}-text-emphasis: #{$value};
    --bs5-#{"" + $key}-bg-subtle: #{color.scale($value, $lightness: 80%, $saturation: -50%)};
    --bs5-#{"" + $key}-border-subtle: #{color.scale($value, $lightness: 60%, $saturation: -50%)};
  }
  --bs5-body-color: #{map.get($bs-incyte-colors, primary)};
  color: var(--bs5-body-color);
}
