.alert {
  border-radius: 5px;
  min-height: 38px;
  padding: 10px;
  display: flex;
  align-items: center;

  &.alert-danger {
    border: solid 1px #fe4848;
    background-color: #fe4848;
    color: #ffffff;
  }

  .alert-icon {
    height: 18px;
    width: 18px;
    font-size: 18px;
    margin-right: 10px;
  }

  .alert-message {
    font-size: 12px;
    display: block;
  }
}
