@import '../../vendor/jointjs';

html {
  font-size: 15px;
}
.stat-sm {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.nav-tabs {
  --bs5-nav-tabs-link-active-bg: white;
}

.nav-pills .nav-link {
  border: 1px solid var(--chitin-primary);
  border-radius: 0;

  &:hover {
    background: var(--chitin-select-input-dropdown-panel-item-hover-bg);
  }

  &.active {
    background: var(--chitin-primary);
  }
}

.nav-pills .nav-item {
  &:first-child .nav-link {
    border-top-left-radius: var(--bs5-nav-pills-border-radius);
    border-bottom-left-radius: var(--bs5-nav-pills-border-radius);
  }

  &:last-child .nav-link {
    border-top-right-radius: var(--bs5-nav-pills-border-radius);
    border-bottom-right-radius: var(--bs5-nav-pills-border-radius);
  }
}

.badge {
  padding-block: 4px;
  &.filter-badge {
    padding: 0.51rem 0.7rem !important;
    border-radius: 10rem !important;
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.1);
  }
}

ng-select {
  .ng-dropdown-panel {
    min-width: 100% !important;
  }
}

pre code.hljs {
  padding: 0 !important;
  overflow-x: unset !important;
}
