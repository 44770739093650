.opacity-0 {
  opacity: 0;
}
.btn {
  box-shadow: $default-box-shadow;

  .loadingSpinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -43%);
  }
}

.input-group {
  .btn {
    box-shadow: none;
  }
}

.btn-outline-secondary {
  color: inherit;
}

.btn-pink,
.btn-pink:hover,
.btn-pink:focus,
.btn-outline-pink:hover,
.btn-outline-pink:focus {
  color: #fff;
  --bs5-btn-hover-color: #fff !important;
}
