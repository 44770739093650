.form-control {
  background-color: var(--chitin-form-input-bg);
  border-color: var(--chitin-form-input-border-color);
  font-size: 0.825rem;

  &:focus {
    border-color: var(--chitin-form-input-focus-border-color);
    box-shadow: 0 0 0 0.25rem var(--chitin-form-input-focus-box-shadow-color);
  }

  &:disabled,
  &.disabled {
    opacity: 0.7;
    background-color: var(--chitin-form-input-bg);
  }
}

.form-label {
  color: $black;
  font-size: 0.85rem;
}

.has-clear {
  position: relative;

  .form-control {
    &.has-value {
      padding-right: 1.45rem;
    }
  }

  .form-control-sm {
    &.has-value {
      padding-right: 1.4rem;
    }
  }

  .form-control-lg {
    &.has-value {
      padding-right: 1.65rem;
    }
  }

  .form-control-clear {
    color: var(--chitin-form-input-clear-button-color);
    position: absolute;
    font-size: 18px;
    z-index: 10;
    cursor: pointer;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);

    &:hover {
      color: var(--chitin-form-input-clear-button-hover-color);
    }
  }
}

.input-group-text {
  font-size: 95%;
}

.is-invalid {
  .form-control {
    border-color: var(--chitin-form-input-invalid-border-color);
  }
}
