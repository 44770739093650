.form-check {
  &.form-switch {
    &.form-check-lg {
      .form-check-input {
        height: 1.55em;
        width: 3em;
      }
    }
  }
}
