@import 'bootstrap/scss/bootstrap-reboot';
@import 'bootstrap/scss/bootstrap-grid';
@import 'bootstrap/scss/bootstrap-utilities';
@import 'bootstrap/scss/modal';

.tiny-tooltip {
  height: 12px;
  margin: -2px 0 0 5px;
  vertical-align: middle;
}

.mapNavWrapper {
  width: calc(100% + 2rem);
  height: calc(100% + 2rem);
  position: relative;
  margin: -1rem;
  overflow: hidden;
  transition: width 0.33s;

  .map {
    width: calc(100% + 260px);
    height: 100%;
  }
}

.popover-p-0 .popover-body {
  padding: 0;
}

.popover-no-max {
  max-width: none !important;
}

.popover-no-body-pad {
  .popover-body {
    padding: 0;
  }
}

.tooltip-primary {
  .tooltip-inner {
    background-color: var(--bs5-primary);
    color: white;
  }
}
