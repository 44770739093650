@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.login-page {
  .page-wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    @include media-breakpoint-up(xl) {
      flex-direction: row;
    }
  }

  .panel {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4rem;
  }

  .info-panel {
    @extend .panel;
    padding-bottom: 4rem;
    display: none;
    @include media-breakpoint-up(xl) {
      display: block;
      padding-left: 5vw;
      padding-top: 20.5rem;
    }
  }

  .login-form {
    @extend .panel;
    padding-bottom: 2rem;
    flex-grow: 1;
    @include media-breakpoint-up(xl) {
      display: block;
      padding-left: 10vw;
      padding-top: 8rem;
    }
  }

  .logo {
    height: 2.5rem;
    width: auto;
    margin-bottom: 5rem;
    @include media-breakpoint-up(xl) {
      margin-bottom: 10rem;
    }
  }

  .login-field,
  .form-control {
    max-width: 256px;
  }

  .forgot-password-link {
    font-size: $font-size-sm;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .pass-reset-description {
    text-align: center;
    max-width: 300px;
    @include media-breakpoint-up(xl) {
      text-align: left;
    }
  }
}
