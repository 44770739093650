.btn-group-my-2 > .btn {
  @extend .mx-2 !optional;
}
html {
  .bs-table {
    table {
      table-layout: fixed;
    }
    .select-control__control-container {
      height: 31px !important;
      .select-control__control {
        top: -5px;
      }
      .ng-select-container {
        height: 31px;
      }
    }
    .text-control__input-and-edit {
      height: 36px !important;
      .text-control__input-container {
        height: 36px !important;
        .text-control__input {
          height: 36px !important;
        }
      }
    }
  }
  ng-select.ng-select-multiple {
    .ng-value {
      display: none;
    }
    &:not(.ng-select-filtered) {
      .ng-placeholder {
        display: block !important;
      }
    }
  }
  ng-dropdown-panel {
    &.dropdown-overflow {
      width: auto !important;
      min-width: 70px !important;
    }
  }
  .offcanvas {
    --bs5-offcanvas-width: 600px;
    background-color: #f0f0f0;
  }

  ngb-datepicker {
    --bs5-primary-rgb: 63, 128, 234;
    border-color: #dee6ed;
    padding: 0.5rem;

    &[data-popper-placement*='bottom-'] {
      top: 0.5rem !important;

      &::before {
        border-bottom: 7px solid #ced4da;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        top: -7px;
        content: '';
        display: inline-block;
        position: absolute;
      }
      &::after {
        border-bottom: 6px solid #fff;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        top: -6px;
        content: '';
        display: inline-block;
        position: absolute;
      }
    }

    &[data-popper-placement='bottom-end'] {
      &::before {
        right: 9px;
      }
      &::after {
        right: 10px;
      }
    }

    &[data-popper-placement='bottom-start'] {
      &::before {
        left: 9px;
      }
      &::after {
        left: 10px;
      }
    }

    [ngbDatepickerDayView] {
      width: 2.25rem;
      height: 1.9rem;
      &:hover {
        background-color: #e2e8ee;
      }
    }
    ngb-datepicker-navigation-select {
      .form-select {
        height: auto;
        background-size: 14px 10px;
        background-position: right 0.5rem top 0.35rem;
        &:last-of-type {
          margin-left: 0.5rem;
        }
      }
    }
    .ngb-dp-weekdays {
      border-bottom: none;
    }
    .ngb-dp-day {
      width: 2.25rem;
      height: 1.9rem;
    }
    .ngb-dp-weekday {
      font-style: normal;
      font-weight: bold;
      font-size: 100%;
      width: 2.25rem;
      height: 1.9rem;
      letter-spacing: -0.5px;
    }
  }
  .custom-control-label {
    margin-left: 0.5rem;
  }
}
