.joint-element--clickable {
  cursor: pointer;
}

.joint-element--active {
  polygon,
  rect {
    stroke: #fe7183;
    stroke-width: 6;
  }
}

.joint-link--active {
  path:nth-child(2) {
    stroke: #fe7183;
    stroke-width: 6;
  }
  text {
    fill: #fe7183;
  }
}
